<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick" v-if="is_show_video">
      <el-tab-pane
        v-for="(item, index) in topList"
        :key="index"
        :data-item="JSON.stringify(item)"
        :label="item.name"
        :name="item.name"
      >
      </el-tab-pane>
      <div class="el-tabs-content">
        <!-- {{ this.$store.state.gradeId }}
        {{ this.$store.state.givePath }} -->
        <div style="font-size: 20px">
          <span
            >共{{ dataLength }}个章节，学习进度:{{ looked }}/{{
              section_num
            }}</span
          >
        </div>
      </div>
      <div class="treeBox">
        <el-tree
          :data="data"
          :props="props"
          :load="loadNode"
          lazy
          @node-click="nodeClick"
        >
          <!-- 0文档 1视频 2图片 3压缩包 4作业 -->
          <span
            class="custom-tree-node"
            slot-scope="{ data }"
            :class="data.is_sign == 1 ? 'is_sign' : ''"
          >
            <div class="topP">
              <div
                v-if="data.is_file || data.is_download == 4"
                class="contentBox"
              >
                <div class="imgT">
                  <img
                    src="../../assets/imgAndFile.png"
                    alt=""
                    v-if="data.is_download == 0"
                  />
                  <img
                    src="../../assets/vedio.png"
                    alt=""
                    v-if="data.is_download == 1"
                  />
                  <el-image
                    style="width: 40px; height: 40px"
                    :src="data.url"
                    :preview-src-list="srcList"
                    v-if="data.is_download == 2"
                  >
                  </el-image>
                  <el-image-viewer
                    v-if="showViewer"
                    :on-close="closeViewer"
                    :url-list="srcList"
                  ></el-image-viewer>
                  <img
                    src="../../assets/file_ext_big_zip.png"
                    v-if="data.is_download == 3"
                    alt=""
                  />
                  <img
                    src="../../assets/web2x_zuoye.png"
                    v-if="data.is_download == 4"
                    alt=""
                  />
                  <p v-if="data.is_download == 4">作业</p>
                  <p v-if="[0, 1, 2, 3].includes(data.is_download)">资料</p>
                </div>
                <div class="rightContent">
                  <div class="ss">
                    <div class="title">
                      <p v-if="data.is_download == 4">
                        {{ data.title }}
                      </p>
                      <p v-if="data.is_download == 1">
                        {{ data.name }}
                      </p>
                      <a
                        v-else
                        :href="data.url"
                        style="text-decoration: none; color: #000"
                        >{{ data.name }}</a
                      >
                    </div>
                    <p>
                      <span v-if="data.is_download == 4"
                        >截止时间：{{ data.close_time }}</span
                      >
                      <span v-else>文件时间：{{ data.update_at }}</span>
                    </p>
                    <p>
                      <span
                        style="margin: 0; font-size: 14px; color: #ff941f"
                        v-if="data.is_download == 4"
                      >
                        {{ data.is_over_text }}—<i
                          :class="
                            data.is_submit > 0 ? 'is_submit' : 'no_submit'
                          "
                          >{{ data.is_submit_text }}</i
                        ></span
                      >
                      <span
                        style="margin: 0; font-size: 14px; color: #ff941f"
                        v-if="data.is_download == 4"
                      >
                        {{ data.status_text }}</span
                      >
                      <span
                        style="margin: 0; font-size: 14px; color: #ff941f"
                        v-else
                      >
                        {{
                          data.end_time ? data.end_time_text : "待学习"
                        }}</span
                      >
                    </p>
                  </div>
                  <el-button
                    type="primary"
                    v-if="data.is_download == 4"
                    @click.stop="submitWork(data)"
                    >提交作业</el-button
                  >
                </div>
              </div>
              <span v-if="!data.is_file">{{ data.name }} </span>
              <span
                v-if="!data.is_file && data.is_download != 4"
                style="margin-left: 10px"
                >线上</span
              >
            </div>
          </span>
        </el-tree>
      </div>
    </el-tabs>
    <messageDialog ref="messageDialog" />
    <div class="diaBox">
      <el-dialog
        title="账号提示"
        :visible.sync="dialogVisible"
        :top="'30vh'"
        width="60%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
      >
        <span style="font-size: 24px"
          >亲爱的同学，您的账号已过期，如想继续使用本系统，请联系相关老师!</span
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { fileList } from "@/api/order.js";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import messageDialog from "@/components/messageDialog.vue";
export default {
  props: {
    gradeID: {
      type: Number,
      default: 0,
    },
    givePath: {
      type: String,
      default: "",
    },
    is_show_video: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      activeName: "必修课",
      props: {
        label: "name",
        isLeaf: "isLeaf",
      },
      data: [],
      csIndex: "",
      tableLoading: false,
      srcList: [],
      dataLength: 0,
      looked: 0,
      section_num: 0,
      showViewer: false, // 显示查看器
      topList: [],
      is_give_class: false,
      is_file_type: 0,
      isFisrst: true,
      path: "",
      dialogVisible: false,
      grade_id: "",
    };
  },

  // is_give 0 下层目录 1上层目录
  created() {
    console.log(1);
    new Promise(function (resolve, reject) {
      reject(11);
      resolve(22);
    }).then(
      function (data) {
        console.log(data);
      },
      function (data) {
        console.log(data);
      }
    );
    console.log(4);
  },
  mounted() {
    // let dirPath = JSON.parse(localStorage.getItem("userInfo")).give_path;
    if (this.isFisrst) {
      fileList({
        dir: "",
        is_give: 1,
      }).then((res) => {
        this.topList = res.data;
        this.dialogVisible = res.is_show_video ? false : true;
        // this.$emit("getBackImg", res);
        // this.topList.unshift({ name: "必修课", path: "" });
        if (
          res.grade_data.sinatv_path &&
          res.grade_data.sinatv_path != "未找到班级视频地址"
        ) {
          this.topList.unshift({
            name: "VIP特辑",
            path: res.grade_data.sinatv_path,
          });
        }
        this.topList.unshift({ name: "必修课", path: "" });
        if (res.message_data.length) {
          this.$refs.messageDialog.show(res.message_data);
        }
      });
      this.getList("", 0, 0);
      setTimeout(() => {
        this.isFisrst = false;
      }, 500);
    }
  },
  components: {
    ElImageViewer,
    messageDialog,
  },
  watch: {
    $route: {
      handler(to, form) {},
      deep: true, // 深度监听
      immediate: true, // 第一次初始化渲染就可以监听到
    },
    // gradeID: {
    //   immediate: true,
    //   handler(val) {
    //     // this.getList(this.givePath, 0, 0, val);
    //   },
    //   deep: true,
    // },
    "$store.state": {
      handler(val) {
        if (!this.isFisrst) {
          this.data = [];
          fileList({
            dir: "",
            index: 0,
            is_give: 1,
            grade_id: val.gradeId,
          }).then((res) => {
            this.topList = res.data;
            // this.$emit("getBackImg", res);
            // this.topList.unshift({ name: "必修课", path: "" });
            if (
              res.grade_data.sinatv_path &&
              res.grade_data.sinatv_path != "未找到班级视频地址"
            ) {
              this.topList.unshift({
                name: "VIP特辑",
                path: res.grade_data.sinatv_path,
              });
            }
            this.topList.unshift({ name: "必修课", path: "" });
            this.activeName = "必修课";
            if (res.message_data.length) {
              this.$refs.messageDialog.show(res.message_data);
            }
          });
          this.getList("", 0, 0, val.gradeId);
          this.is_file_type = 0;
          console.log(val.givePath, val.gradeId);
          this.grade_id = val.gradeId;
        } else {
          // this.getList(val.givePath, 0, 1, val.gradeId);
        }
      },
      deep: true,
    },
  },

  methods: {
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([]);
      }
      // if (node.level > 1) return resolve([]);
      // if (!this.is_file) {
      setTimeout(() => {
        fileList({
          dir: this.path,
          is_give: this.is_file_type,
          grade_id: this.gradeID,
        }).then((res) => {
          // 单级目录--打开就视频
          // let pathLevel =
          //   this.is_give == 0
          //     ? this.path.split("/").length - 1
          //     : this.path.split("/").length - 2;
          // let mp4Arr = res.data.filter((item) => item.is_download == "1");
          // if (mp4Arr.length && pathLevel < 2) {
          //   console.log("不正常目录");
          //   this.quesData = res.data;
          //   console.log( this.quesData );
          // } else {
          //   console.log("正常目录");
          //   console.log(res.data);
          //   this.normalData = res.data;
          // }
          let arr = res.data.map((item) => {
            if (item.is_file == 1 || item.is_download == 4) {
              item.isLeaf = true;
              return item;
            } else {
              return item;
            }
          });
          resolve(arr);
        });
      }, 100);
      // }
    },
    nodeClick(item) {
      this.path = item.path;
      this.is_file = item.is_file;
      this.srcList = [];
      if (item.is_download == 2) {
        this.srcList.push(item.url);
        this.showViewer = true;
        return;
      }
      item.is_give = this.is_file_type;
      console.log(item);
      // 0文档 1视频 2图片 3压缩包 4作业
      if (item.is_download == 1) {
        // ss = [1, 2];
        // s = 1;
        // x = 2;

        // s = 2;
        // x_s = [1, 2];
        // x = 1;

        let routeData = this.$router.resolve({
          path: "/studyDetail",
          query: {
            item: JSON.stringify(item),
            is_give: this.is_file_type,
            grade_id: this.gradeID,
          },
        });
        window.open(routeData.href, "_blank");
        // this.normalData = [];
        return;
      }
      if (item.is_download == 4) {
        let routeData = this.$router.resolve({
          path: "/homeWork",
          query: { id: item.id },
        });
        //    query: item,
        window.open(routeData.href, "_blank");
        return;
      }
      if (item.is_download == 0 || item.is_download == 3) {
        window.open(item.url);
        return;
      }
    },
    // 提交作业
    submitWork(item) {
      let routeData = this.$router.resolve({
        path: "/homeWork",
        query: { id: item.id },
      });
      //    query: item,
      window.open(routeData.href, "_blank");
    },
    // 获取列表数据
    getList(dir, index, type, grade_id) {
      this.tableLoading = true;
      fileList({ dir, is_give: type, grade_id }).then((res) => {
        if (!this.data.length) {
          this.data = res.data;
          this.dataLength = res.data.length;
          this.looked = res.look_num;
          this.section_num = res.section_num;
          localStorage.setItem("look_num", res.look_num);
          localStorage.setItem("section_num", res.section_num);
          localStorage.setItem("look_num_bfb", res.look_num_bfb);
          localStorage.setItem("stu_job_submit_bfb", res.stu_job_submit_bfb);
          this.tableLoading = false;
        }
      });
    },

    // 关闭查看器
    closeViewer() {
      this.showViewer = false;
    },
    // 第一个ul点击 is_download  0文档 1视频 2图片 3压缩包 4作业

    firstLiFn(item, index) {
      this.srcList = [];
      if (item.is_download == 2) {
        this.srcList.push(item.url);
        this.showViewer = true;
        return;
      }
      if (item.is_download == 1) {
        // this.$router.push({ name: "studyDetail", query: item });
        let routeData = this.$router.resolve({
          path: "/studyDetail",
          query: item,
        });
        window.open(routeData.href, "_blank");
        sessionStorage.removeItem("allData");
        return;
      }
      if (item.is_download == 4) {
        let routeData = this.$router.resolve({
          path: "/homeWork",
          query: { id: item.id },
        });
        //    query: item,
        window.open(routeData.href, "_blank");
        return;
      }
      if (item.is_download == 0 || item.is_download == 3) {
        window.open(item.url);
        return;
      }
      if (item.is_open) {
        item.is_open = !item.is_open;
        return;
      }
      this.getList(item.path, index, this.is_file_type, this.gradeID);
      item.is_open = !item.is_open;
    },
    // 目录点击
    handleClick(tab, event) {
      this.data = [];
      let item = JSON.parse(tab.$el.dataset.item);
      console.log(tab);
      if (item.name == "必修课") {
        this.getList("", 0, 0, this.gradeID);
        this.is_file_type = 0;
        this.is_give_class = false;
      } else if (item.name == "VIP特辑") {
        this.getList(item.path, 0, 2, this.gradeID);
        this.is_give_class = false;
        this.is_file_type = 2;
      } else {
        this.getList(item.path, 0, 1, this.gradeID);
        this.is_give_class = true;
        this.is_file_type = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-tabs__item {
  padding: 0px 20px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  font-weight: 500;
  user-select: none;
  // color: #409eff;
}
.treeBox {
  margin-top: 20px;
  margin-bottom: 140px;
}
.rotateEdg {
  transform: rotate(90deg);
}
.noRotateEdg {
  transform: rotate(0deg);
}

::v-deep .el-icon-caret-right {
  font-size: 24px;
  transition: all 0.3s linear;
}
::v-deep .el-tabs__nav-prev,
::v-deep .el-tabs__nav-next {
  top: 10px;
  font-size: 16px;
}
.is_sign {
  color: #4285f4 !important;
}
.is_submit {
  color: #4285f4;
}
.no_submit {
  color: red;
}
.custom-tree-node {
  font-size: 20px;
  color: #000;
  width: 100%;
  img {
    width: 40px;
    height: 40px;
  }
  .topP {
    display: flex;
    align-items: center;
    span {
      &:last-child {
        color: #000;
        font-size: 16px;
      }
    }
    .imgT {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      font-size: 14px;
    }
    .contentBox {
      width: 100%;
      display: flex;
      align-items: center;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;
      .rightContent {
        flex: 1;
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .ss {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          p {
            &:nth-child(1) {
              font-size: 18px;
              color: #212121;
              cursor: pointer;
            }
            &:nth-child(2) {
              span {
                font-size: 14px;
                color: #5f6368;
                margin: 0;
              }
            }
            &:nth-child(3) {
              font-size: 14px;
              color: #ff941f;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-tabs__item {
  font-size: 18px;
  padding: 0 14px;
  box-sizing: border-box;
}
::v-deep .el-tree-node {
  margin-bottom: 10px;
  background: #eeeeee;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  &:hover {
    background: #eeeeee;
    cursor: pointer;
  }
}
::v-deep .el-tree-node.is-expanded > .el-tree-node__children .el-tree-node {
  margin: 0;
  padding: 10px;
  border-radius: 0px;
  box-sizing: border-box;
}
::v-deep .el-tree-node__content {
  height: auto;
  margin-left: 0;
}
::v-deep .el-tree-node__content .el-tree-node {
  background: #fff;
}
::v-deep .el-tree-node__content:hover,
.el-upload-list__item:hover,
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background: #eeeeee;
}
::v-deep .el-icon-caret-right,
::v-deep .el-tree-node__expand-icon.expanded {
  pointer-events: none !important;
}
</style>

<template>
  <div class="fapiaoAdmin">
    <el-dialog
      title="发票管理"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="6">
          <el-input v-model="input" placeholder="请输入姓名/手机号"></el-input>
        </el-col>
        <el-button
          icon="el-icon-search"
          style="margin-left: 10px"
          @click="getList()"
          >搜索</el-button
        >
        <el-button
          type="warning"
          icon="el-icon-circle-plus"
          @click="addFpInfoFn"
          >申请开票</el-button
        >
        <el-button type="warning" icon="el-icon-circle-plus" @click="addFpNNFn"
          >添加开票信息</el-button
        >
      </el-row>
      <div class="tableBox">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          v-loading="tableLoading"
        >
          <el-table-column prop="user_name" label="抬头类型" align="center" />
          <el-table-column prop="number" label="联系电话" align="center" />
          <el-table-column prop="amount" label="开票金额" align="center" />
          <el-table-column prop="invoice_type" label="发票类型" align="center">
            <template slot-scope="scope">
              {{ scope.row.invoice_type == 1 ? "电子普通发票" : "" }}
            </template>
          </el-table-column>
          <el-table-column
            prop="invoice_content"
            label="发票内容"
            align="center"
          />
          <el-table-column
            prop="payment_time"
            label="缴费时间"
            align="center"
          />
          <el-table-column
            prop="other_notice"
            label="其他说明"
            show-overflow-tooltip
            align="center"
          />
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <el-tag>{{
                scope.row.status < 4
                  ? "待开票"
                  : scope.row.status == 10
                  ? "已打回"
                  : scope.row.status == 11
                  ? "待开票"
                  : "已开票"
              }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                v-if="scope.row.status == 4"
                type="text"
                size="small"
                >下载发票</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="lookWhy(scope.row, false)"
                v-if="scope.row.status == 10"
                >查看打回原因</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="lookWhy(scope.row, true)"
                v-if="scope.row.status == 10"
                >重新提交</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
    <addFpInfo ref="addFpInfo" />
    <addFpInfoNN ref="addFpInfoNN" />
    <dahuiYY ref="dahuiYY" />
  </div>
</template>

<script>
import * as http from "@/api/order";
import addFpInfo from "@/components/addFpInfo.vue";
import addFpInfoNN from "@/components/addFpInfoNN.vue";
import dahuiYY from "@/components/dahuiYY.vue";

export default {
  data() {
    return {
      dialogVisible: false,
      input: "",
      tableData: [],
      tableLoading: false,
    };
  },
  components: {
    addFpInfo,
    addFpInfoNN,
    dahuiYY,
  },

  methods: {
    getList() {
      this.tableLoading = true;
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      http
        .invoiceIndex({ user_id: userInfo.id, keyword: this.input })
        .then((res) => {
          this.tableData = res.data;
          this.tableLoading = false;
        });
    },
    addFpInfoFn() {
      this.$refs.addFpInfo.show();
    },
    addFpNNFn() {
      this.$refs.addFpInfoNN.show();
    },
    handleClick(row) {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let params = {
        invoice_id: row.id,
        user_id: userInfo.id,
        username: userInfo.student_name,
      };
      http.download_invoice({ ...params }).then((res) => {
        this.$message({
          message: "即将下载发票",
          type: "success",
        });
      });
      window.open(row.invoice_file);
    },
    lookWhy(row, type) {
      this.$refs.dahuiYY.show(row, type);
    },
    show() {
      this.dialogVisible = true;
      this.getList();
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.tableBox {
  margin-top: 10px;
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
</style>

<template>
  <div class="fapiaoAdmin">
    <el-dialog
      :title="isAdd ? '添加发票信息' : '编辑发票信息'"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="姓名" prop="user_name">
          <el-input
            v-model.trim="ruleForm.user_name"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="number">
          <el-input
            v-model="ruleForm.number"
            placeholder="请输入电话"
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :loading="btnLoading"
            >保 存</el-button
          >
          <el-button @click="handleClose">关 闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import * as http from "@/api/order";
export default {
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      infos: JSON.parse(localStorage.getItem("userInfo")),
      isAdd: false,
      id: "",
      ruleForm: {
        user_name: "",
        number: "",
      },
      rules: {
        user_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        number: [
          //非空验证
          { required: true, message: "请输入手机号", trigger: "blur" },
          // 手机号格式
          {
            required: true,
            pattern: /^1\d{10}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.ruleForm.user_id = this.infos.id;
          if (this.isAdd) {
            http
              .operate_invoice_info({
                ...this.ruleForm,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.btnLoading = false;
                  this.handleClose();
                }
              })
              .catch((_) => {
                this.btnLoading = false;
              });
          } else {
            this.ruleForm.invoice_info_id = this.id;
            http
              .operate_invoice_info({
                ...this.ruleForm,
              })
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.msg,
                    type: "success",
                  });
                  this.btnLoading = false;
                  this.handleClose();
                }
              })
              .catch((_) => {
                this.btnLoading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    show() {
      this.dialogVisible = true;
      http.view_invoice_info({ user_id: this.infos.id }).then((res) => {
        if (res.data != null) {
          this.ruleForm.user_name = res.data.user_name;
          this.ruleForm.number = res.data.number;
          this.id = res.data.id;
          this.isAdd = false;
        } else {
          this.isAdd = true;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.resetForm("ruleForm");
      this.btnLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
</style>

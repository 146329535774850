<template>
  <div class="fapiaoAdmin">
    <el-dialog
      title="打回原因"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-input type="textarea" :rows="2" disabled v-model="row.dh_notice">
      </el-input>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        v-if="againSubmit"
        style="margin-top: 20px"
      >
        <el-form-item label="姓名">
          <el-input v-model="ruleForm.user_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="ruleForm.number" disabled></el-input>
        </el-form-item>
        <el-form-item label="金额" prop="amount">
          <el-input v-model.trim="ruleForm.amount"></el-input>
        </el-form-item>
        <el-form-item label="缴费时间" prop="payment_time">
          <el-date-picker
            v-model="ruleForm.payment_time"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择缴费时间"
            style="width: 100%"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            :loading="btnLoading"
            >提 交</el-button
          >
          <el-button @click="handleClose">关 闭</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer" v-if="!againSubmit">
        <el-button type="primary" @click="againSubmitFn">重新提交</el-button>
        <el-button @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as http from "@/api/order";
export default {
  data() {
    return {
      dialogVisible: false,
      infos: JSON.parse(localStorage.getItem("userInfo")),
      row: {},
      btnLoading: false,
      againSubmit: false,
      ruleForm: {
        user_name: "",
        number: "",
        amount: "",
        payment_time: "",
      },
      rules: {
        amount: [
          { required: true, message: "请输入发票金额", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback();
              }
              if (!/^(\d+|\d+.\d{1,2})$/.test(value)) {
                callback(new Error("请输入正确的金额格式"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        payment_time: [
          { required: true, message: "请选择缴费时间", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    againSubmitFn() {
      this.againSubmit = true;
      this.ruleForm.user_name = this.row.user_name;
      this.ruleForm.number = this.row.number;
      this.ruleForm.amount = this.row.amount;
      this.ruleForm.payment_time = this.row.payment_time;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          this.ruleForm.user_id = this.infos.id;
          this.ruleForm.invoice_id = this.row.id;
          http
            .add_invoice({ ...this.ruleForm })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                this.btnLoading = false;
                this.$parent.getList();
                this.handleClose();
              }
            })
            .catch((_) => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    show(row, type) {
      this.againSubmit = type;
      this.row = row;
      if (type) {
        this.ruleForm.user_name = this.row.user_name;
        this.ruleForm.number = this.row.number;
        this.ruleForm.amount = this.row.amount;
        this.ruleForm.payment_time = this.row.payment_time;
      }
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.againSubmit = false;
      this.btnLoading = false;
      if (this.againSubmit) {
        this.resetForm("ruleForm");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
</style>
